import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Fizjoterapia = () => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO
        seoTitle="Laseroterapia Kraków"
        description="Nasza gabinet laseroterapii w Krakowie to zespół najlepszych specjalistów."
      />

      <GlobalTop title="Lasoreterapia Kraków" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Czym jest
                <strong>Laser Frakcyjno-Ablacyjny Perfectus?</strong>
              </h2>
              <p>
                Laser frakcyjno-ablacyjny Perfectus to urządzenie medyczne
                wykorzystujące skoncentrowane wiązki światła do precyzyjnego
                leczenia uszkodzeń skórnych. Jego działanie opiera się na
                frakcyjnej ablacji, czyli procesie, w którym laser usuwa bardzo
                cienkie warstwy tkanki skórnej, jednocześnie pozostawiając
                otaczające obszary nienaruszone. Dzięki temu proces{" "}
                <strong>
                  gojenia jest znacznie przyspieszony, a ryzyko powikłań jest
                  minimalizowane.
                </strong>
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/laseroterapia/laseroterapia-image-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider" />
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/laseroterapia/laseroterapia-image-2.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Jakie zabiegi
                <br />
                można wykonać
                <br />
                przy pomocy
                <strong>lasera frakcyjno-ablacyjnego?</strong>
              </h2>
              <p>
                Laser Perfectus znalazł zastosowanie w wielu obszarach medycyny
                estetycznej i podologii, w tym w:
              </p>
              <ul>
                <li>
                  odmładzaniu skóry: usuwa oznaki starzenia, zmarszczki i drobne
                  linie,
                </li>
                <li>
                  redukcji blizn: skuteczny w leczeniu blizn potrądzikowych,
                  pooperacyjnych oraz innych rodzajów blizn,
                </li>
                <li>
                  leczeniu rozstępów: zwiększa elastyczność skóry i poprawia jej
                  wygląd,
                </li>
                <li>
                  usuwanie zmian skórnych: takich jak brodawki, hiperkeratozy, a
                  nawet niektóre rodzaje zmian barwnikowych,
                </li>
                <li>
                  usuwanie odcisków: Laser frakcyjny emituje skoncentrowane
                  wiązki światła, które precyzyjnie usuwają zrogowaciałą skórę
                  tworzącą odciski, nie uszkadzając przy tym zdrowej tkanki
                  wokół,
                </li>
                <li>
                  regeneracja łożyska przy onycholize: Laser może stymulować
                  komórki skóry do produkcji kolagenu i innych czynników
                  wzrostu, co przyczynia się do szybszej regeneracji tkanki,
                </li>
                <li>
                  leczenie grzybicy paznokci: laser frakcyjny emituje światło o
                  określonej długości fali, które jest skuteczne w niszczeniu
                  komórek grzybów odpowiedzialnych za infekcję.
                </li>
              </ul>
            </div>
          </div>
          <div className="divider" />
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Kto może korzystać
                <br />z usług <strong className="inline">laseroterapii?</strong>
              </h2>
              <p>
                <strong>
                  Laseroterapia, stosowana w wielu dziedzinach medycyny i
                  kosmetologii, jest dostępna dla szerokiej grupy osób
                </strong>
                , w tym dla pacjentów z problemami skórnymi, takimi jak blizny
                (potrądzikowe, pooperacyjne, pourazowe), rozstępy, czy niektóre
                rodzaje zmian skórnych np. brodawki, hiperkeratozy, łagodne
                zmiany barwnikowe. Jest to także opcja dla osób chcących
                poprawić wygląd swojej skóry, redukować zmarszczki, linie i inne
                oznaki starzenia, a także poprawić teksturę i jędrność skóry.
                Pacjenci z dolegliwościami stóp, takimi jak grzybica paznokci,
                odciski, brodawki, mogą również korzystać z laseroterapii.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/laseroterapia/laseroterapia-image-3.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider" />
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/laseroterapia/laseroterapia-image-4.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Jakie są <strong>przeciwwskazania?</strong>
              </h2>
              <p>
                Jednakże istnieją pewne ograniczenia i przeciwwskazania do
                stosowania laseroterapii. Nie jest ona zalecana dla kobiet w
                ciąży, osób z aktywnymi infekcjami skórnymi w miejscu
                planowanego zabiegu, osób z pewnymi schorzeniami skórnymi
                (takimi jak łuszczyca) w aktywnej fazie, osób z historią
                fotodermatozy lub nietolerancji światła, oraz osób z niektórymi
                rodzajami nowotworów.{" "}
                <strong>
                  Ważne jest, aby przed rozpoczęciem terapii przeprowadzić
                  konsultację z wykwalifikowanym specjalistą, który oceni
                  zdolność do poddania się zabiegowi i wykluczy ewentualne
                  przeciwwskazania.
                </strong>
              </p>
              <div className="divider" />
              <h2 className="section--header">
                Skuteczne <strong>leczenie</strong>
              </h2>
              <p>
                <strong>
                  Dzięki precyzyjnej technologii, laser pozwala na dokładne i
                  skuteczne leczenie oraz minimalizuje ryzyko powikłań i skutków
                  ubocznych.
                </strong>{" "}
                Laser frakcyjno-ablacyjny Perfectus stanowi istotny krok naprzód
                w dziedzinie leczenia i regeneracji skóry. Ten nowoczesny sprzęt
                łączy zaawansowaną technologię z precyzją działania, oferując
                szeroki zakres zastosowań i znaczące efekty w leczeniu i
                regeneracji skóry.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default Fizjoterapia
